@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Extrabold"), local("Gilroy-Extrabold"),
    url("Gilroy-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy UltraLight Italic"), local("Gilroy-UltraLightItalic"),
    url("Gilroy-UltraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Bold Italic"), local("Gilroy-BoldItalic"),
    url("Gilroy-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Semibold"), local("Gilroy-Semibold"),
    url("Gilroy-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Black Italic"), local("Gilroy-BlackItalic"),
    url("Gilroy-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Thin Italic"), local("Gilroy-ThinItalic"),
    url("Gilroy-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Extrabold Italic"), local("Gilroy-ExtraboldItalic"),
    url("Gilroy-ExtraboldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Black"), local("Gilroy-Black"),
    url("Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Heavy Italic"), local("Gilroy-HeavyItalic"),
    url("Gilroy-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Semibold Italic"), local("Gilroy-SemiboldItalic"),
    url("Gilroy-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Medium"), local("Gilroy-Medium"),
    url("Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Regular Italic"), local("Gilroy-RegularItalic"),
    url("Gilroy-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Light"), local("Gilroy-Light"),
    url("Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Light Italic"), local("Gilroy-LightItalic"),
    url("Gilroy-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Heavy"), local("Gilroy-Heavy"),
    url("Gilroy-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Medium Italic"), local("Gilroy-MediumItalic"),
    url("Gilroy-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Thin"), local("Gilroy-Thin"),
    url("Gilroy-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
